// Import React
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import "./Receipt.css";
import toast from "react-hot-toast";

const Receipt = ({ orders, isAuthenticated }) => {
  // Find the order with the matching ID
  const { orderId } = useParams();
  const order = orders.find(({ id }) => String(id) === String(orderId));
  const [orderItems, setOrderItems] = React.useState(order?.order_items || []);
  console.log("order Items", orderItems);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      // toast.error("Please login first");
      navigate("/");
    }
  }, [isAuthenticated]);


  console.log("value of order", order)

  if (!order) {
    return <div>Order not found</div>;
  }


  const initialSubtotal = orderItems
    .reduce(
      (acc, item) =>
        acc +
        parseFloat(item.sale_price) *
        item.purchase_quantity,
      0
    )
    .toFixed(2)
  const initialDeliveryFee = order.delivery_charge ? order.delivery_charge : 0
  const initialDiscount = order.discount_amount ? order.delivery_charge : 0
  const initialTotal = Number(initialDeliveryFee) + Number(initialSubtotal) - Number(initialDiscount);


  // Dte Formatting
  let date = new Date(order.created_at);
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  let year = date.getFullYear();

  let formattedDate = `${day}-${month}-${year}`;

  function deleteOrderItem(id) {
    setOrderItems(orderItems.filter((item) => item.id !== id));
  }

  return (
    <div>
      <div>
        <div>
          <h1 className="text-3xl font-bold text-center mt-2">
            FreshBuyzar.com
          </h1>
          <h1 className="text-2xl font-bold text-center my-1">
            Order Id: {order.id}
          </h1>
          <hr className="border-t border-black" />
          <h2 className="text-3xl font-bold text-center my-4">{formattedDate}</h2>
          <hr className="border-t border-black" />

          {/* <div>
            <h2 className="text-2xl font-bold text-left mt-1 ml-2"
            >
              Name:&nbsp;&nbsp;
              <span style={{ color: "black", display: "inline" }}>
                {order?.user_details?.first_name}
              </span>
            </h2>
          </div> */}

          <div>
            <h2
              className="text-2xl font-bold text-left mt-1  ml-2"
            >
              Address:&nbsp;&nbsp;
              <span style={{ color: "black", display: "inline" }}>
                {order?.delivery_address?.address}
              </span>
            </h2>
          </div>

          <div>
            <h2
              className="text-2xl font-bold text-left ml-2 mb-1"
            >
              Contact:&nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontWeight: "medium",
                  display: "inline",
                }}
              >
                {order?.user_details?.mobile_number}
              </span>
            </h2>
          </div>
          <div>
            <h2
              className="text-2xl font-bold text-left ml-2 mb-1"
            >
              Payment mode:&nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontWeight: "medium",
                  display: "inline",
                }}
              >
                {order?.payment_method}
              </span>
            </h2>
          </div>
          <hr className="border-t border-black" />

          <div style={{ paddingLeft: 5 }} className="my-1">
            <table width="100%" cellPadding="10">
              <tbody>
                <th>No.</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
                {/* Dynamic rendering of items */}
                {orderItems.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {index + 1}  <span className="ml-4 text-red-600 cursor-pointer noprint" onClick={() => deleteOrderItem(item.id)}> X</span>
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {item.name} ({item.unit})
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      {item.purchase_quantity}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      ₹{item.sale_price}
                    </td>
                    <td style={{ fontSize: 25, textAlign: "center" }}>
                      ₹{(
                        parseFloat(item.sale_price) *
                        item.purchase_quantity
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr className="border-t border-black" />

          <div className="mt-4 mx-6">

            <div style={{ marginTop: -10 }} className="flex-container mb-2">
              <h3 style={{ paddingLeft: 5, color: "black" }}>Delivery Fees:</h3>
              <h3 style={{ paddingLeft: 5, color: "black", textAlign: "right" }}>
                ₹{initialDeliveryFee}
              </h3>
            </div>

            {/* Subtotal, Delivery fee, Discount, and Total */}
            <div style={{ marginTop: -10 }} className="flex-container">
              <h3 style={{ paddingLeft: 5, color: "black" }}>Subtotal:</h3>
              <h3 style={{ paddingLeft: 5, color: "black", textAlign: "right" }}>
                ₹{initialSubtotal}
              </h3>
            </div>

            {order.wallet_point && order.wallet_point?.amount > 0 && (
              <div style={{ marginTop: -10 }} className="flex-container">
                <h3 style={{ paddingLeft: 5, color: "black" }}>
                  Wallet Points Used:
                </h3>
                <h3
                  style={{ paddingLeft: 5, color: "black", textAlign: "right" }}
                >
                  ₹-{order.wallet_point?.amount}
                </h3>
              </div>
            )}

            <h3
              style={{
                display: "flex",
                color: "black",
                justifyContent: "space-between",
              }}
            >
              <div>Total:</div>
              <div>
                ₹
                {initialTotal}
              </div>
            </h3>

          </div>

          {/* Print button */}
          <div className="flex justify-center">
            <button
              onClick={() => window.print()}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-6 noprint"
            >
              Print Receipt
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Receipt;
