import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { useNavigate, } from "react-router-dom";
import { getOtp, verifyOtp } from "../services/apiService";

const LoginSignUp = ({ isAuthenticated, setIsAuthenticated }) => {
    const navigate = useNavigate();
    const [number, setNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('')
    const loginSubmit = async (e) => {
        e.preventDefault();

        if (number.length < 10) {
            toast.error("Please enter a valid number");
            return;
        }
        try {
            await getOtp(number)
            setOtpSent(true);
            toast.success("OTP sent successfully");
        } catch (error) {
            setOtpSent(false);
            toast.error("Error sending otp")
            console.log(error)
        }


    };

    const otpSubmit = async (e) => {
        e.preventDefault();

        if (otp.length !== 4) {
            toast.error("Please enter 4 digit Number Otp");
            return;
        }
        try {
            const res = await verifyOtp(number, otp)
            localStorage.setItem("accessToken", res.access)
            localStorage.setItem("refreshToken", res.refresh)
            setIsAuthenticated(true)
            // Hard reload the page
            window.location.reload();
            toast.success("OTP verified successfully");
        } catch (error) {
            setOtpSent(false)
            toast.error("error verfying otp")
            console.log(error)
        }


    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/pending");
        }
    }, [isAuthenticated]);

    return (
        <>
            <div className=" pt-[4rem]">
                <div className="w-full flex flex-col justify-center items-center">

                    {!otpSent && <p className="text-2xl font-bold ">LOGIN With your Number</p>
                    }
                    {otpSent && <p className="text-2xl font-bold ">Verify your Otp</p>
                    }
                    {!otpSent && <form className="flex flex-col" onSubmit={loginSubmit}>

                        <input
                            type="number"
                            placeholder="Enetr your Number"
                            required
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            className="border border-black p-2  rounded-md my-12"
                        />

                        <input type="submit" value="get OTP" className="border border-black p-2  rounded-md cursor-pointer bg-black text-white" />
                    </form>}

                    {otpSent && <form className="flex flex-col" onSubmit={otpSubmit}>

                        <input
                            type="number"
                            placeholder="Enetr your OTP"
                            required
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="border border-black p-2  rounded-md my-12"
                        />

                        <input type="submit" value="verify OTP" className="border border-black p-2  rounded-md cursor-pointer bg-black text-white" />
                    </form>}

                </div>
            </div>
        </>
    );
};

export default LoginSignUp;
