import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Products({ isAuthenticated }) {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);

    // fetching products
    async function fetchProducts() {
        try {
            setLoading(true);
            const { data } = await axios.get("https://api-prod.freshbuyzar.com/products/");
            setProducts(data);
            setLoading(false);
        } catch (error) {
            toast.error("Error fetching products");
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div className="flex justify-center ">

            {loading ? <div className='text-4xl flex items-center justify-center min-h-[80vh]'>Loading...</div> : <table className=" bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-blue-500 text-white">
                    <tr>
                        <th className="py-3 px-5 text-left">Name</th>
                        <th className="py-3 px-5 text-left">Price</th>
                        <th className="py-3 px-5 text-left">Sale Price</th>
                        <th className="py-3 px-5 text-left">Unit</th>
                        <th className="py-3 px-5 text-left">Quantity</th>
                        <th className="py-3 px-5 text-left">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {products && products.map((product, index) => (
                        <ProductCard key={index} product={product} />
                    ))}
                </tbody>
            </table>}
        </div>
    )
}

export default Products


function ProductCard({ product }) {
    const [quantity, setQuantity] = useState(product.quantity);
    const [price, setPrice] = useState(product.price);
    const [salePrice, setSalePrice] = useState(product.sale_price);
    const [unit, setUnit] = useState(product.unit);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function updateProduct(id) {
        const token = localStorage.getItem("accessToken")
        try {
            setLoading(true);
            await axios.patch(`https://api-prod.freshbuyzar.com/products/${id}/`,
                { price, sale_price: salePrice, unit, quantity },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }

            )
            toast.success("Product updated successfully")
            setLoading(false);

            // Call the Next.js revalidation API
            await fetch(`https://freshbuyzar.com/api/revalidate?secret=${"process.env.NEXT_PUBLIC_MY_SECRET_TOKEN"}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            navigate("/")
        } catch (error) {
            console.log(error)
            setLoading(false);
            toast.error("Error updating product")
        }

    }

    return <tr key={product.id} className=" hover:bg-gray-100">
        <td className="py-3 px-5">{product.name} ({product.unit})</td>
        <td className="py-3 px-5 ">
            {/* {product.price} */}
            <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} className='border border-gray-300 rounded px-2' />
        </td>
        <td className="py-3 px-5">
            <input type="text" value={salePrice} onChange={(e) => setSalePrice(e.target.value)} className='border border-gray-300 rounded w-auto px-2' />
        </td>
        <td className="py-3 px-5">
            <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} className='border border-gray-300 rounded w-auto px-2' />
        </td>
        <td className="py-3 px-5">
            <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} className='border border-gray-300 rounded w-auto px-2' />
        </td>
        <td className="py-3 px-5">
            <button className='border border-gray-300 rounded w-auto px-2 bg-blue-900 text-white'
                onClick={() => updateProduct(product.slug)}
                disabled={loading}>{loading ? "Updating..." : "Update"}</button>
        </td>
    </tr>
}
