import React from "react";
import Receipt from "./components/Receipt"; // Make sure this path is correct
import { useState, useEffect } from "react";
import Myorders from "./components/Myorders";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Allorders from "./components/Allorders";
import PurchaseOrders from "./components/PurchaseOrders";
import toast, { Toaster } from "react-hot-toast";
import LoginSignUp from "./components/LoginSignUp";
import axios from "axios";
import Products from "./components/Products.js";

function App() {
  const PENDING_URL = "https://api-prod.freshbuyzar.com/orders/admin/pending/"
  const ALLORDERS_URI = "https://api-prod.freshbuyzar.com/orders/admin/orders/"
  const PURCHASE_ORDER_URI = "https://api-prod.freshbuyzar.com/orders/admin/purchase-orders/"
  const [pendingOrders, setPendingOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});


  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    // Function to fetch pending orders
    async function fetchPendingOrders() {
      try {
        const { data } = await axios.get(PENDING_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPendingOrders(data);
      } catch (error) {
        toast.error("Error fetching orders:");
      }
    }

    // function to fetch all orders
    async function fetchAllOrders() {
      try {
        const { data } = await axios.get(ALLORDERS_URI, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAllOrders(data);
      } catch (error) {
        toast.error("Error fetching orders:");
      }
    }

    // function to fetch purchase orders
    async function fetchPurchaseOrders() {
      try {
        const { data } = await axios.get(PURCHASE_ORDER_URI);
        setPurchaseOrders(data);
      } catch (error) {
        toast.error("Error fetching orders:");
      }
    }

    fetchPendingOrders();
    fetchAllOrders()
    fetchPurchaseOrders()

    if (localStorage.getItem("accessToken") && localStorage.getItem("refreshToken")) {
      setIsAuthenticated(true);
    }
  }, []);

  function logOutHandler() {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    setIsAuthenticated(false)
    toast.success("Logged out successfully")
  }

  return (
    <Router>
      <div><Toaster toastOptions={{ duration: 2000 }} /></div>
      {isAuthenticated && <div className="text-center my-6 noprint  flex justify-center gap-4 flex-wrap  w-[80vw]mx-auto">
        <Link to="/pending" className="">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Pending Orders</button>
        </Link>
        <Link to="/all" className="">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">All Orders</button>
        </Link>
        <Link to="/purchase">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Purchase Orders</button>
        </Link>
        <Link to="/products">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">Products</button>
        </Link>

        <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
          onClick={logOutHandler}>Logout</button>

      </div>}
      <Routes>
        <Route path="/" element={<LoginSignUp isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} user={user} setUser={setUser} />} />


        <Route path="/pending" element={<Myorders orders={pendingOrders} isAuthenticated={isAuthenticated} />} />
        <Route path="/all" element={<Allorders orders={allOrders} isAuthenticated={isAuthenticated} />} />
        <Route path="/purchase" element={<PurchaseOrders purchaseOrders={purchaseOrders} isAuthenticated={isAuthenticated} />} />
        <Route path="/:orderId" element={<Receipt orders={allOrders} isAuthenticated={isAuthenticated} />} />
        <Route path="/products" element={<Products isAuthenticated={isAuthenticated} />} />
      </Routes>
    </Router>
  );
}

export default App;
