import axios from "axios";

const OTP_URI = "https://api-prod.freshbuyzar.com/auth/send-otp/";
const OTP_VERIFY_URI = "https://api-prod.freshbuyzar.com/auth/verify-otp/";

// function to send otp
async function getOtp(mobileNumber) {
    try {
        const res = await axios.post(OTP_URI, {
            mobile_number: mobileNumber,
        });

        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// function to verify otp
async function verifyOtp(mobileNumber, otpReceived) {
    try {
        const res = await axios.post(OTP_VERIFY_URI, {
            mobile_number: mobileNumber,
            otp: otpReceived,
        });

        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export { getOtp, verifyOtp }

