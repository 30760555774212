import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function PurchaseOrders({ purchaseOrders, isAuthenticated }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);

    return (
        <div className="flex justify-center ">
            <table className=" bg-white shadow-md rounded-lg overflow-hidden">
                <thead className="bg-blue-500 text-white">
                    <tr>
                        <th className="py-3 px-5 text-left">Name</th>
                        <th className="py-3 px-5 text-left">Sale Price</th>
                        <th className="py-3 px-5 text-left">Unit</th>
                        <th className="py-3 px-5 text-left">Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {purchaseOrders && purchaseOrders.map((order, index) => (
                        <tr key={index} className="border-b hover:bg-gray-100">
                            <td className="py-3 px-5">{order.name} ({order.unit})</td>
                            <td className="py-3 px-5">{order.sale_price}</td>
                            <td className="py-3 px-5">{order.unit}</td>
                            <td className="py-3 px-5">{order.total_purchase_quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PurchaseOrders
