
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function Allorders({ orders, isAuthenticated }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated]);
    return (
        <div className="mx-auto  w-[100%] lg:w-[70%]">
            <div className="mb-5 sm:mb-10 lg:ml-5 flex items-center justify-between">
                <h2 className="text-black font-semibold sm:text-6xl text-xl  ml-4 lg:ml-0 text-center w-full mt-8">
                    All Orders
                </h2>
            </div>
            <OrderTable orders={orders} />
        </div>
    );
}

export default Allorders;

// OrderTable component

function OrderTable({ orders }) {
    return (
        <>
            <div className="overflow-auto  mt-12 ">
                <div className="mx-auto">
                    <div className="flex flex-col space-y-4">
                        {orders && orders.map((order, index) => (
                            <OrderCard index={index} order={order} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}



function OrderCard({ order, index }) {
    const [expandedCard, setExpandedCard] = React.useState(null);
    const [selectedStatus, setSelectedStatus] = React.useState(order.status);
    const navigate = useNavigate();
    // Function to toggle card expansion
    const toggleExpansion = (index) => {
        if (expandedCard === index) {
            setExpandedCard(null); // Collapse if the same card is clicked again
        } else {
            setExpandedCard(index); // Expand the clicked card
        }
    };

    function printHandler(event, order) {
        event.stopPropagation(); // Prevent the card from expanding
        // console.log("vlue of order inside print handler", order)
        navigate(`/${order.id}`);
    }
    const statusColors = {
        PLACED: "bg-gray-400 text-gray-800",
        PROCESSING: "bg-yellow-100 text-yellow-800",
        PACKED: "bg-blue-100 text-blue-800",
        OUT_FOR_DELIVERY: "bg-orange-100 text-orange-800",
        COMPLETED: "bg-green-100 text-green-800",
        CANCELLED: "bg-red-100 text-red-800",
    };
    const ORDER_STATUS_CHOICES = [
        "PLACED",
        "PROCESSING",
        "PACKED",
        "OUT_FOR_DELIVERY",
        "COMPLETED",
        "CANCELLED",
    ];
    // status change handler
    const handleStatusChange = async () => {
        const token = localStorage.getItem("accessToken")
        try {
            const response = await fetch(`https://api-prod.freshbuyzar.com/orders/admin/${order.id}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ status: selectedStatus }),
            });

            if (response.ok) {
                toast.success("Order status updated successfully!");
                setTimeout(() => {
                    window.location.reload(); // Reload the page on successful update
                }, 2000);
            } else {
                toast.error("Error updating order status.");
            }
        } catch (error) {
            toast.error("Error updating order status.");
        }
    };


    // payment status class switcher
    const getPaymentStatusClass = (status) => {
        switch (status) {
            case "pending":
                return "bg-yellow-100 text-yellow-800";
            case "paid":
                return "bg-green-700 text-white";
            case "failed":
                return "bg-[#f87171] text-white";
            default:
                return "";
        }
    };


    return <div
        key={index}
        className="border p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-300 ease-in-out cursor-pointer relative"
        onClick={() => toggleExpansion(index)}
    >
        <div className={`text-xs font-semibold px-2 py-0.5 rounded ${statusColors[order.status]}`}>
            <b>Order Status: </b>
            {order.status}
        </div>

        <div className="flex justify-between items-start">
            <div className="flex-1">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-bold mb-2">
                        {new Date(order.created_at).toLocaleDateString()}
                    </h3>
                    <div className="bg-blue-100 text-blue-800 text-xs font-semibold px-2 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                        {order.order_items.length} items
                    </div>
                </div>

                <p className="text-gray-700 font-semibold mb-1">
                    <b>Delivery Time Slot: </b>
                    {order.time_slot}
                </p>
                <p className="text-gray-700 font-semibold mb-1">
                    <b>Order Placed On: </b>
                    {new Date(order.created_at).toLocaleDateString()}
                </p>
                <p className="text-gray-700 font-semibold mb-1">
                    <b>Payment Method: </b>
                    {order.payment_method}
                </p>
                <p className="text-gray-700 font-semibold">
                    <strong>Address: </strong>
                    {order.delivery_address.address},{" "}
                    {order.delivery_address.city}
                </p>
                <p className="text-gray-700 font-semibold">
                    <strong>Note: </strong>
                    {order?.note ? order?.note : "Null"}
                </p>
                <p className="text-grey-700 font-semibold">
                    <b>Total Amount: </b>₹
                    {order.order_items
                        .reduce(
                            (acc, item) =>
                                acc +
                                parseFloat(item.sale_price) *
                                item.purchase_quantity,
                            0
                        )
                        .toFixed(2)}
                </p>

                {/* Payment status */}

                <div className="mt-2 flex justify-between">
                    <span
                        className={`text-xs font-semibold px-2 py-0.5 rounded ${getPaymentStatusClass(
                            order.payment_status
                        )}`}
                    >
                        <b>Payment Status: </b>

                        {order.payment_status.charAt(0).toUpperCase() +
                            order.payment_status.slice(1)}
                    </span>
                </div>

            </div>

        </div>

        {/* Status change */}
        <div className="mt-4 lg:absolute top-14 right-[180px]">
            <label className="block text-gray-700 font-semibold mb-2">
                Select Status
            </label>
            <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                onClick={(e) => e.stopPropagation()} // Stop event propagation
                className="block  mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
                {ORDER_STATUS_CHOICES.map((status) => (
                    <option key={status} value={status}>
                        {status}
                    </option>
                ))}
            </select>
            <button
                className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the card from expanding
                    handleStatusChange();
                }}
            >
                Update Status
            </button>
        </div>

        {/* print button */}
        <div className="flex justify-end">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={(event) => printHandler(event, order)}>Print receipt</button>
        </div>

        {expandedCard === index && (
            <div className="mt-4 space-y-2">
                {order.order_items.map((item, itemIndex) => (
                    <div
                        key={itemIndex}
                        className="flex justify-between items-center pt-2 border-t"
                    >
                        <div className="flex-1">
                            <p className="text-gray-800 font-semibold">
                                {item.name} ({item.unit})
                            </p>
                            <p className="text-gray-800">
                                Price: ₹ {item.sale_price}
                            </p>
                            <p className="text-gray-800">
                                Quantity: {item.purchase_quantity}
                            </p>
                            <p className="text-gray-800">
                                Total: ₹{" "}
                                {(
                                    parseFloat(item.sale_price) *
                                    item.purchase_quantity
                                ).toFixed(2)}
                            </p>
                            <p className="text-gray-800">Unit: {item.unit}</p>
                        </div>
                        {item.feature_image && (
                            <img
                                src={item.feature_image}
                                alt={item.name}
                                className="w-24 h-24 object-cover rounded ml-4"
                            />
                        )}
                    </div>
                ))}
            </div>
        )}
    </div>
}